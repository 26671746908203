//flow
import React from "react";
import { PostList } from "../../components";
import { withAuth, withAlgoliaConnect} from '../../HOC';
import {compose,withProps} from 'recompose'
import {PostTemplate} from "../../page-components/posts";
import PropTypes from "prop-types";
import type { IPostHit } from "../../definitions";
import { userHasId, userHasRole } from "../../util";
const INDEX_NAME  = 'prod_BLOG'

const Component = ({isAuth}:any) => {
  const isAuthFunc = (postHit:IPostHit)=>userHasRole('admin') || userHasId(postHit.user);
  return (
      <PostTemplate isAuth={isAuth} isAdmin={userHasRole('admin')}>
        <PostList isAuth={isAuthFunc} cols={2} />
      </PostTemplate>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  withAuth(),
  withProps(({isAuth,filters})=>{
    // const filters=_category?`_category:${_category}`:`_category:publication`;
    if(isAuth && !userHasRole('admin')) {
      return {filters:`${filters} OR user:${isAuth.id}`}
    }
    return {filters}
  }),
  withAlgoliaConnect(INDEX_NAME),
)(Component);
